import React, { useState } from "react";
import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import * as Sentry from "@sentry/react";
import AddIcon from "@mui/icons-material/Add";
import CopyAll from "@mui/icons-material/CopyAll";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import { NotificationModal } from "@/components/NotificationModal";
import { fm } from "@/contexts/IntlContext";
import { useCurrentUser } from "@/entities/users";
import {
    DataRepository,
    useDeleteRepositoryRowDeleteQueryMutation,
    useDeleteRowsByDeleteQueryMutation,
    useGetRepositoryRowDeleteQueriesByRepositoryQuery,
} from "@/generated/client";
import { testIdPrefix } from "../../../testIdPrefix";
import { DeleteConfigBuilder } from "../DeleteConfigBuilder";
import messages from "./messages";
import { Option } from "./option";

export interface DeleteInDateRangeFilter {
    startDate: Date;
    endDate: Date;
    dateFormat: string;
    dateField: string;
}

interface Props {
    currentRepository: DataRepository;
}

export const DeleteByQuery: React.FC<Props> = ({ currentRepository }) => {
    const [open, setOpen] = useState(false);
    const [openQueryBuilder, setOpenQueryBuilder] = useState(false);
    const [deleteQueryId, setDeleteQueryId] = useState<string>();
    const { result, refetch } = useGetRepositoryRowDeleteQueriesByRepositoryQuery({
        input: { dataRepositoryId: currentRepository.id },
    });
    const [notfificationId, setNotificationId] = useState<string>();
    const [deleteRepositoryRowDeleteQuery] = useDeleteRepositoryRowDeleteQueryMutation({
        refetchQueries: ["getRepositoryRowDeleteQueriesByRepository"],
    });
    const [runDelete] = useDeleteRowsByDeleteQueryMutation();
    const user = useCurrentUser();

    const handleDelete = () => {
        deleteQueryId &&
            runDelete({ input: { deleteQueryId } })
                .then((res) => setNotificationId(res.data?.deleteRowsByDeleteQuery.notificationId))
                .catch((e) => {
                    Sentry.captureException(e, { tags: { app: "data-repositories-app" } });
                });
    };

    const handleDeleteQuery = (id: string) => {
        deleteRepositoryRowDeleteQuery({ input: { id } }).catch((e) => {
            Sentry.captureException(e, { tags: { app: "data-repositories-app" } });
        });
    };

    return !open ? (
        <Button
            sx={{ whiteSpace: "nowrap", minWidth: "260px" }}
            onClick={() => setOpen(true)}
            size="small"
            color="secondary"
            variant="outlined"
            startIcon={<CopyAll />}
            data-testid={`${testIdPrefix}-open-delete-configuration-button`}
        >
            {fm(messages.manageDeleteQueries)}
        </Button>
    ) : (
        <>
            <Dialog open={open} onClose={() => setOpen(false)}>
                {notfificationId && (
                    <NotificationModal
                        open={notfificationId !== undefined}
                        onClose={() => setOpen(false)}
                        notificationId={notfificationId}
                        title="Deleting data"
                    />
                )}
                <GraphqlRequestContainer
                    asyncData={result}
                    loading={
                        <Stack>
                            <Skeleton height={2} width="80%" />
                            <Skeleton height={3} width="100%" />
                            <Skeleton height={2} width="30px" />
                        </Stack>
                    }
                >
                    {(response) => (
                        <>
                            <DialogTitle>{fm(messages.deleteHeader)}</DialogTitle>
                            <DialogContent>
                                <Stack gap={1} sx={{ paddingTop: 1 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="select-label-delete-query">
                                            {fm(messages.selectDeleteQuery)}
                                        </InputLabel>
                                        <Select
                                            labelId="select-label-delete-query"
                                            id="select-component-delete-query"
                                            value={deleteQueryId}
                                            label={fm(messages.selectDeleteQuery)}
                                            renderValue={(value) => {
                                                const query = response.repositoryRowDeleteQueries.find(
                                                    (innerQuery) => innerQuery.id === value
                                                );
                                                return (
                                                    <Typography>
                                                        {" "}
                                                        {query ? query.queryType.split("_").join(" ") : ""}{" "}
                                                    </Typography>
                                                );
                                            }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <Grid paddingRight={1}>
                                                        <Tooltip title={fm(messages.newConfig)}>
                                                            <IconButton
                                                                onClick={() => setOpenQueryBuilder(true)}
                                                                color="success"
                                                            >
                                                                <AddIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </InputAdornment>
                                            }
                                            onChange={({ target }) => setDeleteQueryId(target.value)}
                                        >
                                            {response.repositoryRowDeleteQueries.map((query) => (
                                                <MenuItem key={`option${query.id}`} value={query.id}>
                                                    <Option
                                                        query={query}
                                                        currentRepository={currentRepository}
                                                        handleDeleteQuery={handleDeleteQuery}
                                                    />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Button
                                        disabled={deleteQueryId === undefined || !user?.email?.endsWith("ignite.no")}
                                        size="small"
                                        color="error"
                                        onClick={() => handleDelete()}
                                    >
                                        {fm(messages.run)}
                                    </Button>
                                </Stack>
                            </DialogContent>
                        </>
                    )}
                </GraphqlRequestContainer>
            </Dialog>
            <DeleteConfigBuilder
                currentRepository={currentRepository}
                open={openQueryBuilder}
                onClose={() => {
                    refetch();
                    setOpenQueryBuilder(false);
                }}
            />
        </>
    );
};
